import React, { Fragment, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import { TRAINING_ACTION_STATUS } from "../../../../config";
import Pagetitle from "../../../../components/Pagetitle";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../../../config";


const GET_TRAINING_ACTIONS_QUERY = gql`
    query GetTrainingActions {
        TrainingActionList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { startDate: ASC }) {
            id
            trainingPlan {
                id
                name
                startDate
                endDate
            }
            name
            trainingEntity {
                id
                name
            }
            startDate
            endDate
            trainingHoursAmount
            cost
            status
        }
    }
`;
const DELETE_MUTATION = gql`
    mutation DeleteTrainingAction($id: String!) {
        delete_TrainingAction(id: $id)
    }
`;

function AdminTrainingActionList() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedStatus, setSelectedStatus] = useState("")

    const { loading, error, data, refetch } = useQuery(GET_TRAINING_ACTIONS_QUERY);

    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteTrainingAction = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a ação de formação?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Ação de formação eliminada com sucesso!')
            }
        }
    }

    const trainingActions = (data && data.TrainingActionList
        .filter(a => selectedStatus === "" || a.status === selectedStatus)
        .slice().sort(function (a, b) {
        const d1 = new Date(a.startDate).getTime()
        const d2 = new Date(b.startDate).getTime()
        return d2 - d1;
    })) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Ações de Formação"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ trainingActions.length } resultados</h4>
                                            <select className="searchCat float-right sort" onChange={e => setSelectedStatus(e.target.value)}>
                                                <option value="">Todas</option>
                                                { TRAINING_ACTION_STATUS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                            </select>

                                            <Link to="/admin/training/trainingActions/new" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Nova Ação</Link>
                                            <Link to="/admin/training/trainingEntities" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Entidades Formadoras</Link>
                                            <Link to="/admin/training/trainingPlans" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Planos de Formação</Link>
                                        
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-success feather-book font-md text-white"></i>
                                                    <h4 className="text-success font-xl fw-700">{trainingActions.length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">ações de formação</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-warning feather-calendar font-md text-white"></i>
                                                    <h4 className="text-warning font-xl fw-700">{ trainingActions.filter(a => a.status === 'PLANNED').length }<span className="fw-500 mt-0 d-block text-grey-500 font-xssss">planeadas</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-loader font-md text-white"></i>
                                                    <h4 className="text-secondary font-xl fw-700">{trainingActions.filter(a => a.status === 'IN_EXECUTION').length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">em curso</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-primary feather-check font-md text-white"></i>
                                                    <h4 className="text-primary font-xl fw-700">{trainingActions.filter(a => a.status === 'COMPLETED').length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">concluídas</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 text-left">Ação</th>
                                                            <th className="border-0 p-4">Plano de Formação</th>
                                                            <th className="border-0 p-4">Entidade Formadora</th>
                                                            <th className="border-0 p-4">Data de Início</th>
                                                            <th className="border-0 p-4">Data de Fim</th>
                                                            <th className="border-0 p-4">Carga Horária</th>
                                                            <th className="border-0 p-4">Custo</th>
                                                            <th className="border-0 p-4">Estado</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trainingActions.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/training/trainingActions/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{ value.trainingPlan ? (value.trainingPlan.name || `(${value.trainingPlan.startDate}-${value.trainingPlan.endDate})`) : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.trainingEntity.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.startDate ? <Moment format="DD/MM/YYYY HH:mm">{value.startDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.endDate ? <Moment format="DD/MM/YYYY HH:mm">{value.endDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.trainingHoursAmount} h</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.cost} €</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${TRAINING_ACTION_STATUS.filter(s => s.id === value.status)[0].className} font-xssss rounded-3`}><strong>{TRAINING_ACTION_STATUS.filter(s => s.id === value.status)[0].name}</strong></span>
                                                            </td>
                                                            <td className="text-right">
                                                                <Link to={`/admin/training/trainingActions/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteTrainingAction(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminTrainingActionList;