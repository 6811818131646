import React,{Component} from 'react';

import { useQuery, gql, useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { SIMPLETEXT_FORMATTER, getImageUrl, getProfilePicture } from '../utils';
import Load from './Load';

const GET_QUERY = gql`
    query Get {
        PostList(limit: 3, filter: { group: { _isNull: false } } orderBy: { createdDate: DESC }) {
            id
            title
            content
            thumbnail
            createdDate
            user {
                id
                name
                picture
                employee {
                    id
                    picture
                    user {
                        id
                        picture
                    }
                }
            }
            group {
                id
                name
                thumbnail
            }
        }
    }
`;

function GroupsPostsWidget() {

    const { loading, error, data } = useQuery(GET_QUERY);

    const navigate = useNavigate();

    if(data?.PostList?.length == 0) return null;

    if(loading) return <Load/>;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 pb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Engage <i className="em em-family ms-1"></i></h4>
                <Link to="/groups" className="fw-600 ms-auto font-xssss text-success">Ver mais</Link>
            </div>
            
            {data?.PostList?.map((value , index) => (
            <div className="wrap pointer" key={index} onClick={() => navigate(`/groups/${value.group?.id}#top`)}>
                <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
                    <figure className="avatar me-3 mb-0">
                        <img
                            src={getProfilePicture(value.user)}
                            alt={value.user?.name}
                            className="shadow-sm rounded-circle w40 aspect-ratio-1"
                            ref={(el) => {
                                if (el) {
                                  el.style.setProperty('max-width', '40px', 'important')
                                }
                            }}
                        />
                    </figure>
                    <h4 className="fw-600 text-grey-900 font-xssss mt-1 mb-2">
                        {value.user?.name} {' '}
                        <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                            <Moment fromNow>{ value.createdDate }</Moment>
                        </span>
                        <span className="font-xsssss ps-1 pe-1 lh-20 rounded-3 ls-2 bg-success d-inline-block text-white mt-1 mb-2"><i class="text-white feather-globe me-1"></i>{value.group?.name}</span>
                        { value.title && <p className="comment-text lh-20 fw-700 font-xssss text-grey-500 mb-0">{value.title}</p> }
                        <p className="comment-text lh-20 fw-500 font-xssss text-grey-500 mb-0 text-max-3-lines" dangerouslySetInnerHTML={{ __html: SIMPLETEXT_FORMATTER(value.content) }}></p>
                        { value.thumbnail && <img src={getImageUrl(value.thumbnail)} alt={value.title} className="img-fluid rounded-xxl" /> }
                    </h4>
                </div>
            </div>
            ))}
        </div>
    );

}

export default GroupsPostsWidget;