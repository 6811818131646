import React,{Component} from 'react';

import { useQuery, gql, useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { getImageUrl } from '../utils';
import Load from './Load';

const GET_QUERY = gql`
    query Get {
        Group_List(limit: 3, orderBy: { createdDate: DESC }) {
            id
            name
            thumbnail
            allowUserPosts
        }
        PostList(limit: 10, filter: { group: { _isNull: false } } orderBy: { createdDate: DESC }) {
            id
            group {
                id
                name
                thumbnail
                allowUserPosts
            }
        }
    }
`;

function GroupsWidget() {

    const { loading, error, data } = useQuery(GET_QUERY);

    const navigate = useNavigate();

    if(data?.Group_List?.length == 0 && data?.PostList?.length == 0) return null;

    const postsGroups = data?.PostList?.map(item => item.group).filter((item, index, self) => index === self.findIndex(obj => obj.id === item.id));

    const groups = (postsGroups?.length > 0 ? postsGroups : (data?.Group_List ?? []).concat(postsGroups ?? []))
                    .filter((item, index, self) => index === self.findIndex(obj => obj.id === item.id))
                    .slice(0, 5);
    
    if(loading) return <Load/>;

    if(groups?.length == 0) return null;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Engage <i className="em em-family ms-1"></i></h4>
                <Link to="/groups" className="fw-600 ms-auto font-xssss text-success">Ver mais</Link>
            </div>
            {groups.map((value , index) => (
            <div key={index} className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden pointer" onClick={() => navigate(`/groups/${value.id}`)}>
                <img src={getImageUrl(value.thumbnail, '/assets/images/no-image.jpg')} alt={value.name} className="rounded-xxxl me-2 aspect-ratio-285-160" style={{width: '35%', objectFit: 'cover'}} />
                <h4 className="fw-700 text-grey-900 font-xssss mt-1"><span className="text-max-2-lines" alt={value.name}>{value.name} </span> 
                <span className="d-block font-xss fw-500 mt-0 lh-4 text-success">{value.allowUserPosts && <i className="feather-radio"></i>}</span>
                </h4>
            </div>
            ))}
        </div>
    );

}

export default GroupsWidget;