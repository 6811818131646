import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";

import { GRAPHQL_MAX_RESULTS_LIMIT, UPLOAD_MAX_FILE_SIZE } from "../../../config";

const GET_LISTS_QUERY = gql`
    query GetLists {
        DocumentCategoryList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        DocumentById(id: $id) {
            id
            name
            reference
            description
            category {
                id
                name
            }
        }
    }
`;

const UPSERT_DOCUMENT_MUTATION = gql`
    mutation Upsert($name: String!, $description: String, $category: inp_DocumentCategory, $reference: String) {
        upsert_Document(document: { name: $name, description: $description, category: $category, reference: $reference }) {
            id
        }
    }
`;

const UPSERT_DOCUMENT_VERSION_MUTATION = gql`
    mutation Upsert($document: inp_Document!, $file: Upload!) {
        upsert_DocumentVersion(documentVersion: { document: $document, file: $file }) {
            id
        }
    }
`;

function AdminNewDocument() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [selectedFile, setSelectedFile] = useState(null);

    const inputFile = useRef(null)

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertDocumentMutation] = useMutation(UPSERT_DOCUMENT_MUTATION);
    const [upsertDocumentVersionMutation] = useMutation(UPSERT_DOCUMENT_VERSION_MUTATION);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.DocumentById);
    }, [editData, listsData]);

    const upsert = async (formData) => {
        if(!id && selectedFile === null) {
            toast.error("Por favor, selecione um ficheiro")
            return
        }

        const upsertResult = await upsertDocumentMutation({ 
            variables: {
                ...formData,
            },
        });

        if(upsertResult.data) {
            if(!id) {
                const upsertDocumentVersionResult = await upsertDocumentVersionMutation({ 
                    variables: {
                        document: { id: upsertResult.data.upsert_Document?.id },
                        file: selectedFile,
                    },
                });

                if(upsertDocumentVersionResult.data) {
                    toast.success('Alterações guardadas!');
                    navigate(`/admin/documents/${upsertResult.data.upsert_Document?.id}`)
                } else {
                    toast.error('Ocorreu um erro ao criar o documento.')
                }
            } else {
                toast.success('Alterações guardadas!');
                navigate(`/admin/documents/${upsertResult.data.upsert_Document?.id}`)
            }
        } else {
            toast.error('Ocorreu um erro ao criar o documento.')
        }
    }

    const onFileChange = async(event) => {
        if(event.target.files[0].size > UPLOAD_MAX_FILE_SIZE) {
            toast.error('O ficheiro excede o tamanho permitido!')
            return;
        }

        setSelectedFile(event.target.files[0])
    };

    const removeFile = () => {
        setSelectedFile(null)
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Documento</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-8 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Referência</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("reference")} />
                                                    {errors.reference && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea className="form-control mb-0 p-3 h75 lh-16" rows="2" {...register("description")} ></textarea>
                                                    {errors.description && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Categoria</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("category.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.DocumentCategoryList && listsData.DocumentCategoryList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.category?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                        </div>
                                        {!id &&
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Ficheiro</label>
                                                            <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} />
                                                            
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar um ficheiro' }</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewDocument;