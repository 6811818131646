import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import WYSIWYGEditor from "../../../components/WYSIWYGEditor";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";

import { getImageUrl } from "../../../utils";
import { resizeImage } from "../../../utils/resize-image";
import { UPLOAD_IMAGE_ACCEPT_FILETYPES } from "../../../config";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../../config";

const GET_LISTS_QUERY = gql`
    query GetLists {
        CityList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
        CompanyIndustryList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $name: String!, $description: String, $vatId: String, $isActive: Boolean, $logo: Upload, $industry: inp_CompanyIndustry, $website: String, $email: String, $phone: String, $mobile: String, $address: String, $zipCode: String) {
        upsert_Company(company: { id: $id, name: $name, description: $description, vatId: $vatId, isActive: $isActive, logo: $logo, industry: $industry, website: $website, email: $email, phone: $phone, mobile: $mobile, address: $address, zipCode: $zipCode }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        CompanyById(id: $id) {
            id
            name
            description
            vatId
            isActive
            logo
            industry {
                id
                name
            }
            website
            email
            phone
            mobile
            city {
                id
                name
            }
            address
            zipCode
            latitude
            longitude
        }
    }
`;

function AdminNewCompany() {

    const navigate = useNavigate();

    let { id } = useParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const [selectedFile, setSelectedFile] = useState(null);
    const [shouldRemoveFile, setShouldRemoveFile] = useState(false);

    const inputFile = useRef(null)

    useEffect(() => {
        reset(editData?.CompanyById);
    }, [editData, listsData]);

    console.log(editData)

    const upsert = async (formData) => {
        // optional select fields
        formData.logo = shouldRemoveFile ? null : selectedFile || editData?.logo;

        if(!formData?.industry?.id || formData?.industry?.id === '') {
            formData.industry = null
        }

        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/companies`)
        }
    }

    const onFileChange = async(event) => { 
        setShouldRemoveFile(false)

        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 720);
        } catch (err) {
            console.log(err);
        }
        setSelectedFile(file)
    };

    const removeFile = () => {
        setSelectedFile(null)
        setShouldRemoveFile(true)
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Empresa</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">NIF</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("vatId")} />
                                                    {errors.vatId && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isActive" {...register("isActive")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isActive">Ativa</label>
                                                    {errors.isActive && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <Controller
                                                        render={({ field }) => <WYSIWYGEditor {...field} editorStyle={{height: '200px', maxHeight: '400px', border: '2px #eee solid'}} />}
                                                        name="description"
                                                        control={control}
                                                    />
                                                    {errors.description && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Área de Atividade</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("industry.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData?.CompanyIndustryList?.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.industry?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Website</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("website")} />
                                                    {errors.website && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Email</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("email")} />
                                                    {errors.email && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telefone</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("phone")} />
                                                    {errors.phone && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telemóvel</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("mobile")} />
                                                    {errors.mobile && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Morada</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("address")} />
                                                    {errors.address && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Código Postal</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("zipCode")} />
                                                    {errors.zipCode && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Logótipo</label>
                                                            <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                                                            { (!editData?.CompanyById?.logo || shouldRemoveFile || selectedFile) && <>
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar um ficheiro' }</span>
                                                            </label>
                                                            </> }
                                                            <br />
                                                            { editData?.CompanyById?.logo && !selectedFile && !shouldRemoveFile && <figure className="mb-0 mt-2 w100 pointer" onClick={() => inputFile.current.click()}><img src={getImageUrl(editData?.CompanyById?.logo)} className="rounded-3 w-100" /></figure> }
                                                            { ((selectedFile || editData?.CompanyById?.logo) && !shouldRemoveFile) && <a role="button" onClick={removeFile}><i className="feather-x text-dark rounded-xxl p-1 bg-grey font-xsssss"></i> Remover</a> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewCompany;