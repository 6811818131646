import React, { Fragment } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';

import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import { TRAINING_ACTION_STATUS } from "../../../../config";
import Moment from "react-moment";

const GET_QUERY = gql`
    query Get($id: String!) {
        TrainingPlanById(id: $id) {
            id
            name
            startDate
            endDate
            budget
            executionPercentage
            trainingActions {
                id
                company {
                    id
                    name
                }
                trainingEntity {
                    id
                    name
                }
                name
                expectedStartDate
                startDate
                endDate
                expectedTrainingHoursAmount
                trainingHoursAmount
                cost
                status
                type {
                    id
                    name
                }
            }
        }
    }
`;

const DELETE_TRAINING_PLAN_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_TrainingPlan(id: $id)
    }
`;

function AdminTrainingPlan() {

    let { id } = useParams();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const [deleteTrainingPlanMutation, { deleteTrainingPlanMutationData }] = useMutation(DELETE_TRAINING_PLAN_MUTATION);
    
    
    const deleteTrainingPlan = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o plano de formação?')) {
            const deleteResult = await deleteTrainingPlanMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Plano de formação eliminado com sucesso!')
                await refetch();
            }
        }
    }

    const trainingActions = (data && data.TrainingPlanById?.trainingActions?.slice()
    .sort(function (a, b) {
        const d1 = new Date(a.startDate).getTime()
        const d2 = new Date(b.startDate).getTime()
        return d2 - d1;
    })) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Plano de Formação</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.TrainingPlanById?.name}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Designação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingPlanById?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Início
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingPlanById?.startDate && <Moment format="DD/MM/YYYY">{data?.TrainingPlanById?.startDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Fim
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingPlanById?.endDate && <Moment format="DD/MM/YYYY">{data?.TrainingPlanById?.endDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Orçamento
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingPlanById?.budget || '-'}€</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">% de Realização
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{Math.round(data?.TrainingPlanById?.executionPercentage * 100)}%</span></h4>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Custo Atual
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingPlanById?.trainingActions?.reduce((n, {cost}) => n + cost, 0)}€</span></h4>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">% Orçamento
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingPlanById?.budget ? Math.round(data?.TrainingPlanById?.trainingActions?.reduce((n, {cost}) => n + cost, 0) / data?.TrainingPlanById?.budget * 100) : '-'}%</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        <a role="button" className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-danger me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => deleteTrainingPlan(data?.TrainingPlanById?.id)}>Eliminar</a>
                                                        <Link to={`/admin/training/trainingPlans/edit/${data?.TrainingPlanById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Ações de Formação</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ trainingActions?.length } resultados</h4>
                                                        <Link to={`/admin/training/trainingActions/new?trainingPlanId=${data?.TrainingPlanById?.id}`} className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Adicionar Ação de Formação</Link>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">Designação</th>
                                                                <th className="border-0 p-2 px-4">Empresa</th>
                                                                <th className="border-0 p-2 px-4">Entidade Formadora</th>
                                                                <th className="border-0 p-2 px-4">Tipo</th>
                                                                <th className="border-0 p-2 px-4">Data de Início (prevista)</th>
                                                                <th className="border-0 p-2 px-4">Data de Início</th>
                                                                <th className="border-0 p-2 px-4">Data de Fim</th>
                                                                <th className="border-0 p-2 px-4">Carga Horária (prevista)</th>
                                                                <th className="border-0 p-2 px-4">Carga Horária</th>
                                                                <th className="border-0 p-2 px-4">Custo</th>
                                                                <th className="border-0 p-2 px-4">Estado</th>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {trainingActions.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-left wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/training/trainingActions/${value.id}`}>{value.name}</Link></span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.company?.name}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.trainingEntity?.name}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.type?.name}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.expectedStartDate ? <Moment format="DD/MM/YYYY HH:mm">{value.expectedStartDate}</Moment> : '-'}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.startDate ? <Moment format="DD/MM/YYYY HH:mm">{value.startDate}</Moment> : '-'}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.endDate ? <Moment format="DD/MM/YYYY HH:mm">{value.endDate}</Moment> : '-'}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.expectedTrainingHoursAmount} h</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.trainingHoursAmount} h</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.cost} €</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className={`d-inline-block p-1 ps-2 pe-2 ${TRAINING_ACTION_STATUS.filter(s => s.id === value.status)[0].className} font-xssss rounded-3`}><strong>{TRAINING_ACTION_STATUS.filter(s => s.id === value.status)[0].name}</strong></span>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminTrainingPlan;