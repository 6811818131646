import React, { Fragment, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../../../config";


const GET_QUERY = gql`
    query Get {
        TrainingPlanList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { startDate: ASC }) {
            id
            name
            startDate
            endDate
            budget
            createdDate
        }
    }
`;
const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_TrainingPlan(id: $id)
    }
`;

function AdminTrainingPlanList() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteTrainingPlan = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o plano de formação?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Plano de formação eliminado com sucesso!')
            }
        }
    }

    const trainingPlans = (data && data.TrainingPlanList?.slice().sort(function (a, b) {
        const d1 = new Date(a.startDate).getTime()
        const d2 = new Date(b.startDate).getTime()
        return d2 - d1;
    })) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Planos de Formação"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ trainingPlans.length } resultados</h4>

                                            <Link to="/admin/training/trainingPlans/new" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Plano</Link>
                                            <Link to="/admin/training/trainingEntities" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Entidades Formadoras</Link>
                                            <Link to="/admin/training/trainingActions" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Ações de Formação</Link>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-success feather-book font-md text-white"></i>
                                                    <h4 className="text-success font-xl fw-700">{trainingPlans.length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">planos de formação</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-warning feather-refresh-cw font-md text-white"></i>
                                                    <h4 className="text-warning font-xl fw-700">{ trainingPlans.filter(e => new Date(e.startDate).getTime() <= new Date().getTime() && new Date(e.endDate).getTime() >= new Date().getTime()).length }<span className="fw-500 mt-0 d-block text-grey-500 font-xssss">ativos</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-x font-md text-white"></i>
                                                    <h4 className="text-secondary font-xl fw-700">{trainingPlans.filter(e => new Date(e.startDate).getTime() > new Date().getTime() || new Date(e.endDate).getTime() < new Date().getTime()).length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">inativos</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-primary feather-dollar-sign font-md text-white"></i>
                                                    <h4 className="text-primary font-xl fw-700">{trainingPlans.filter(e => e.budget === null).length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">s/ orçamento definido</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 text-left">Designação</th>
                                                            <th className="border-0 p-4">Data de Início</th>
                                                            <th className="border-0 p-4">Data de Fim</th>
                                                            <th className="border-0 p-4">Orçamento</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trainingPlans.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/training/trainingPlans/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.startDate ? <Moment format="DD/MM/YYYY">{value.startDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.endDate ? <Moment format="DD/MM/YYYY">{value.endDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.budget} €</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <Link to={`/admin/training/trainingPlans/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteTrainingPlan(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminTrainingPlanList;