import React,{Component, useContext, useState, useEffect} from 'react';
import { Link , NavLink } from 'react-router-dom';

import { RBACWrapper } from "react-simple-rbac";

import Darkbutton from '../components/Darkbutton';
import NotificationsDropdown from './NotificationsDropdown';

import { getInitials } from '../utils';
import { config } from '../config';
import { useAuth } from '../providers/authProvider';

const featureList = [
    {
        title: 'Colaboradores',
        icon: 'feather-users',
        link: '/admin/employees',
    },
    {
        title: 'Engage/Comunidades',
        icon: 'feather-volume-2',
        link: '/admin/groups',
        //requiredRoles: ['post-contributor'],
    },
    {
        title: 'Notícias',
        icon: 'feather-tv',
        link: '/admin/news',
        requiredRoles: ['post-contributor'],
    },
    {
        title: 'Eventos',
        icon: 'feather-calendar',
        link: '/admin/events',
        requiredRoles: ['post-contributor'],
    },
    {
        title: 'Bem-estar',
        icon: 'feather-sun',
        link: '/admin/moodtracker',
    },
    {
        title: 'Aplausos',
        icon: 'feather-award',
        link: '/admin/badges',
    },
    {
        title: 'Benefícios e Parcerias',
        icon: 'feather-gift',
        link: '/admin/benefits',
        requiredRoles: ['post-contributor'],
    },
    {
        title: 'Market',
        icon: 'feather-shopping-bag',
        link: '/admin/market/orders',
    },
    {
        title: 'Learning',
        icon: 'feather-book',
        link: '/admin/learning',
        requiredRoles: ['post-contributor'],
    },
    {
        title: 'Formação',
        icon: 'feather-briefcase',
        link: '/admin/training/trainingActions',
    },
    {
        title: 'Bolsas de Estudo',
        icon: 'feather-book-open',
        link: '/admin/scholarships/funds',
    },
    {
        title: 'Canal de Denúncias',
        icon: 'feather-volume-1',
        link: '/admin/whistleblowing/complaints',
    },
    {
        title: 'Recrutamento',
        icon: 'feather-user-plus',
        link: '/admin/recruitment',
    },
    {
        title: 'Refer a Friend',
        icon: 'feather-users',
        link: '/admin/referrals',
    },
    {
        title: 'Links Rápidos',
        icon: 'feather-link',
        link: '/admin/quicklinks',
    },
    {
        title: 'Ideias e Sugestões',
        icon: 'feather-archive',
        link: '/admin/suggestions',
    },
    {
        title: 'Documentos',
        icon: 'feather-folder',
        link: '/admin/documents',
    },
    /*{
        title: 'Turnos',
        icon: 'feather-clock',
        link: '/admin/shifts',
    }*/
    
]

function AdminHeader() {

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);
    
    const auth = useAuth()
    
    useEffect(() => {
        if(auth.isAuthenticated()) {
            auth.getUser().then(u => {
                setCurrentUser(u?.profile)
            })
        }
    }, [auth])

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleActive = () => setIsActive(!isActive);

    const navClass = `${isOpen ? " nav-active" : ""}`;
    const buttonClass = `${isOpen ? " active" : ""}`;
    const searchClass = `${isActive ? " show" : ""}`;

    const userInitials = getInitials(currentUser?.name);

    return (
        <div className="nav-header bg-white shadow-xs border-0">
            <div className="nav-top">
                <Link to="/feed">{ true && <i className="icon-empathize text-success display2-size me-2 ms-0"></i> } <span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-xxl logo-text mb-0">empathize </span> </Link>
                { false && <Link to="/defaultmessage" className="mob-menu ms-auto me-2 chat-active-btn"><i className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i></Link> }
                { false && <Link to="/defaultvideo" className="mob-menu me-2"><i className="feather-video text-grey-900 font-sm btn-round-md bg-greylight"></i></Link> }
                <span onClick={toggleActive} className="ms-auto me-2 menu-search-icon mob-menu"><i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></span>
                <button onClick={toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
            </div>
            
            <form className="float-left header-search ms-3" action="/employees" method="get">
                <div className="form-group mb-0 icon-input">
                    <i className="feather-search font-sm text-grey-400"></i>
                    <input type="text" placeholder="Pesquisa..." className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg" name="q" autoComplete="off" />
                </div>
            </form>
            <NavLink activeclassname="active" to="/feed" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink activeclassname="active" to="/employees" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-users font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink activeclassname="active" to="/market" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink activeclassname="active" to="/learning" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-book font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/okr/objectives" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-target font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/defaultstorie" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/defaultvideo" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/defaultgroup" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/shop2" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }

            <NotificationsDropdown/>
            
            { false && <Link to="/defaultmessage" className="p-2 text-center ms-3 menu-icon chat-active-btn"><i className="feather-message-square font-xl text-current"></i></Link> }
            { false && <Darkbutton /> }
            {false && <Link to="/settings" className="p-0 ms-3 menu-icon"><img src="assets/images/user.png" alt="user" className="w40 mt--1" /></Link>}
            <Link to="/settings" className="p-0 ms-3 menu-icon"><span className="btn-round-sm bg-primary-gradiant mt--1 ls-3 text-white font-xssss fw-700">{userInitials}</span></Link>

            <nav className={`navigation scroll-bar ${navClass}`}>
                <div className="container ps-0 pe-0">
                    <div className="nav-content">
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Administração</span></div>
                            <ul className="mb-1">
                                { featureList.map(e => (
                                    <RBACWrapper requiredRoles={['admin', ...(e.requiredRoles || [])]} oneOf key={e.title}>
                                    <li><Link to={e.link} className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className={`font-sm text-current ${e.icon} me-3`}></i><span>{e.title}</span></Link></li>
                                    </RBACWrapper>
                                ))}
                            </ul>
                        </div>

                        <RBACWrapper requiredRoles={['admin']} oneOf>
                            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                                <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Definições Gerais</span></div>
                                <ul className="mb-1">
                                    <li><Link to="/admin/companies" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm text-current feather-home me-3"></i><span>Empresas</span></Link></li>
                                    <li><Link to="/admin/departments" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm text-current feather-briefcase me-3"></i><span>Departamentos</span></Link></li>
                                    <li><Link to="/admin/staticPages" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm text-current feather-layout me-3"></i><span>Páginas</span></Link></li>
                                    <li><Link to="/admin/faqs" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm text-current feather-help-circle me-3"></i><span>FAQs</span></Link></li>
                                </ul>
                            </div>
                        </RBACWrapper>
                    </div>
                </div>
            </nav>
            
            <div className={`app-header-search ${searchClass}`}>
                <form className="search-form" action="/employees" method="get">
                    <div className="form-group searchbox mb-0 border-0 p-1">
                        <input type="text" className="form-control border-0" placeholder="Pesquisa..." name="q" autoComplete="off" />
                        <i className="input-icon">
                            <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
                        </i>
                        <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                            <i className="ti-close font-xs" onClick={toggleActive}></i>
                        </span>
                    </div>
                </form>
            </div>
            
        </div>
    );
}

export default AdminHeader;