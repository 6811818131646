import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import AdminMediaGallery from "../../../components/AdminMediaGallery";
import Moment from "react-moment";
import { getAttachmentUrl, getImageUrl } from "../../../utils";

const GET_LISTS_QUERY = gql`
    query GetLists {
        BenefitPartnerList(orderBy: { name: ASC }) {
            id
            name
        }
        BenefitCategoryList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        BenefitById(id: $id) {
            id
            name
            headline
            description
            partner {
                id
                name
            }
            category {
                id
                name
            }
            thumbnail
            promocode
            isAvailable
            expirationDate
            mediaGallery {
                id
                name
                items {
                    id
                    name
                    file
                    displayOrder
                }
            }
            attachments {
                id
                file
                name
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Benefit(id: $id)
    }
`;

const UPSERT_ATTACHMENT_MUTATION = gql`
    mutation Upsert($benefitId: UUID, $file: Upload) {
        upsert_BenefitAttachment(benefitAttachment: { benefit: { id: $benefitId }, file: $file }) {
            id
        }
    }
`;

const DELETE_ATTACHMENT_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_BenefitAttachment(id: $id)
    }
`;

const UPSERT_BENEFIT_MEDIA_GALLERY_MUTATION = gql`
    mutation Upsert($id: UUID!, $galleryId: UUID!) {
        upsert_Benefit(benefit: { id: $id, mediaGallery: { id: $galleryId } }) {
            id
        }
    }
`;

const CREATE_NEW_BENEFIT_POST_MUTATION = gql`
    mutation CreateNewBenefitPost($benefitId: UUID, $content: String) {
        CreateNewBenefitPost(benefitId: $benefitId, content: $content) {
            id
        }
    }
`;


function AdminBenefit() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [isUploadOpen, setIsUploadOpen] = useState(false)

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);

    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);
    const [upsertAttachmentMutation, { upsertAttachmentMutationData }] = useMutation(UPSERT_ATTACHMENT_MUTATION);
    const [deleteAttachmentMutation, { deletetAttachmentMutationData }] = useMutation(DELETE_ATTACHMENT_MUTATION);
    const [upsertAssociateMediaGalleryMutation, { upsertBenefitMediagalleryMutationData }] = useMutation(UPSERT_BENEFIT_MEDIA_GALLERY_MUTATION);

    const [createNewBenefitPostMutation] = useMutation(CREATE_NEW_BENEFIT_POST_MUTATION);

    const createNewBenefitPost = async (benefitId, content) => {
        if(window.confirm('Tem a certeza que pretende publicar este benefício no feed?')) {
            const upsertResult = await createNewBenefitPostMutation({ 
                variables: {
                    benefitId: benefitId,
                    content: content
                },
            })

            if(upsertResult.data) {
                toast.success(`Post publicado com sucesso`)
            }
        }
    }

    const deleteBenefit = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o benefício/parceria?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Benefício eliminado com sucesso!')
                navigate(`/admin/benefits`)
            }
        }
    }
    
    const onFileChange = async (event) => { 
        const upsertResult = await upsertAttachmentMutation({ 
            variables: {
                benefitId: id,
                file: event.target.files[0]
            },
        });

        if(upsertResult.data) {
            setIsUploadOpen(false)
            toast.success('Ficheiro anexado com sucesso!')
            await refetch();
        }
    };

    const deleteAttachment = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover o anexo?')) {
            const deleteResult = await deleteAttachmentMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Anexo removido com sucesso!')
                await refetch();
            }
        }
    }

    const associateMediaGallery = async(galleryId) => {
        return upsertAssociateMediaGalleryMutation({ 
            variables: {
                id: data.BenefitById?.id,
                galleryId
            },
        });
    }

    return (
        <RBACWrapper requiredRoles={['admin', 'post-contributor']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Benefício/Parceria</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.BenefitById?.name}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Título
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.BenefitById?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Subtítulo
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.BenefitById?.headline}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Descrição
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500 post-preview-container" dangerouslySetInnerHTML={{ __html: data?.BenefitById?.description }}></span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Categoria
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.BenefitById?.category?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Parceiro
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><Link to={`/admin/benefits/partners/edit/${data?.BenefitById?.partner?.id}`}>{data?.BenefitById?.partner?.name}</Link></span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">

                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Disponível? 
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><i className={`${data?.BenefitById?.isAvailable ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i></span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Validade
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.BenefitById?.expirationDate && <Moment format="DD/MM/YYYY">{data?.BenefitById?.expirationDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Imagem</h4>
                                                            { data?.BenefitById?.thumbnail && <figure className="mb-0 mt-2 w100 pointer"><img src={getImageUrl(data?.BenefitById?.thumbnail)} className="rounded-3 w-100" /></figure> }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Anexos</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <ul className="mb-3">
                                                        {data?.BenefitById?.attachments.map(attachment => (
                                                            <li key={attachment.id}>
                                                                <a href={getAttachmentUrl(attachment.file)} className="d-inline font-xssss fw-600 ls-1 text-grey-700 text-dark mb-2">
                                                                    <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                                </a>
                                                                <a role="button" onClick={() => deleteAttachment(attachment.id)}><i className="font-xss text-danger feather-trash ms-2"></i></a>
                                                            </li>
                                                        ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                                {isUploadOpen &&
                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                            <div className="form-group mb-0 w-100">
                                                                <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                                <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                                <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                                                                <span className="js-fileName">Clique para selecionar um ficheiro</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        { !isUploadOpen && <button className="p-2 ps-3 pe-3 lh-20 bg-dark border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left" onClick={() => setIsUploadOpen(true)}><i className="ti-clip text-white"></i> Anexar ficheiro</button> }
                                                        { <a role="button" className={`p-2 ps-3 pe-3 lh-20 bg-success border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left`} onClick={() => createNewBenefitPost(data?.BenefitById?.id, "")}>Publicar no Feed</a> }
                                                        <a role="button" className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-danger me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => deleteBenefit(data?.BenefitById?.id)}>Eliminar</a>
                                                        <Link to={`/admin/benefits/edit/${data?.BenefitById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>
                                                
                                                <AdminMediaGallery mediaGallery={data?.BenefitById?.mediaGallery} associateMediaGallery={associateMediaGallery} refetch={refetch} />

                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminBenefit;