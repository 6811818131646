import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from "../../../../components/Appfooter";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../../../config";
import moment from "moment";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query GetLists {
        CompanyList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
        DepartmentList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $name: String, $startDate: LocalDate, $endDate: LocalDate, $budget: Float) {
        upsert_TrainingPlan(trainingPlan: { id: $id, name: $name, startDate: $startDate, endDate: $endDate, budget: $budget }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        TrainingPlanById(id: $id) {
            id
            name
            startDate
            endDate
            budget
        }
    }
`;

function AdminNewTrainingPlan() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const watchName = watch("name");

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.TrainingPlanById);
    }, [editData, searchParams, listsData]);

    const upsert = async (formData) => {
        // optional select fields
        

        if(formData.startDate) {
            formData.startDate = moment(formData.startDate).format('YYYY-MM-DD')
        } else {
            formData.startDate = null;
        }

        if(formData.endDate) {
            formData.endDate = moment(formData.endDate).format('YYYY-MM-DD')
        } else {
            formData.endDate = null;
        }

        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/training/trainingPlans/${upsertResult.data.upsert_TrainingPlan.id}`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Plano de Formação</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Designação</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name")} />
                                                    {errors.name && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Início</label>
                                                    { /* <input type="datetime-local" className="form-control" autoComplete="off" {...register("startDate")} /> */ }
                                                    <Controller
                                                        control={control}
                                                        name="startDate"
                                                        rules={{ valueAsDate: true, required: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY') : ""}
                                                                onChange={(date) => setValue(field.name, date.toDate())}
                                                                format={"DD/MM/YYYY"}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.startDate && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Fim</label>
                                                    { /* <input type="datetime-local" className="form-control" autoComplete="off" {...register("endDate")} /> */ }
                                                    <Controller
                                                        control={control}
                                                        name="endDate"
                                                        rules={{ valueAsDate: true, required: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY') : ""}
                                                                onChange={(date) => setValue(field.name, date.toDate())}
                                                                format={"DD/MM/YYYY"}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.endDate && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Orçamento</label>
                                                    <input type="number" min="0" step="0.01" className="form-control" autoComplete="off" {...register("budget", { valueAsNumber: true })} />
                                                    {errors.budget && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewTrainingPlan;