import React, { Fragment, useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import DatePicker from "react-multi-date-picker";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import { getImageUrl, getProfilePicture } from "../../../utils";
import { GRAPHQL_MAX_RESULTS_LIMIT, PERSON_GENDER_OPTIONS } from "../../../config";
import { resizeImage } from "../../../utils/resize-image";
import moment from "moment";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query GetLists {
        EmployeeList(orderBy: { name: ASC }, limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } }) {
            id
            name
            jobTitle
        }
        CompanyList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
        DepartmentList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $fullName: String$, name: String, $email: String, $upn: String, $phone: String, $mobile: String, $birthdate: LocalDate, $gender: PersonGender, $externalId: String, $company: inp_Company, $department: inp_Department, $jobTitle: String, $hireDate: LocalDate, $terminationDate: LocalDate, $isExternal: Boolean!, $isActive: Boolean!, $hideFromOrgChart: Boolean, $manager: inp_Employee, $picture: Upload) {
        upsert_Employee(employee: { id: $id, fullName: $fullName, name: $name, email: $email, upn: $upn, phone: $phone, mobile: $mobile, birthdate: $birthdate, gender: $gender, externalId: $externalId, company: $company, department: $department, jobTitle: $jobTitle, hireDate: $hireDate, terminationDate: $terminationDate, isExternal: $isExternal, isActive: $isActive, hideFromOrgChart: $hideFromOrgChart, manager: $manager, picture: $picture }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        EmployeeById(id: $id) {
            id
            externalId
            name
            fullName
            email
            upn
            phone
            mobile
            mobilePersonal
            whatsapp
            birthdate
            gender
            company {
                id
            }
            department {
                id
            }
            jobTitle
            hireDate
            terminationDate
            isExternal
            hideFromOrgChart
            isActive
            manager {
                id
            }
            picture
        }
    }
`;

function AdminNewEmployee() {

    const navigate = useNavigate();
    
    let { id } = useParams();

    const [selectedFile, setSelectedFile] = useState(null);

    const inputFile = useRef(null) 

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();
    const watchName = watch("name");

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.EmployeeById);
    }, [editData, listsData]);

    const upsert = async (formData) => {
        // optional select fields
        if(!formData?.manager?.id || formData?.manager?.id === '') {
            formData.manager = null
        }

        if(!formData?.company?.id || formData?.company?.id === '') {
            formData.company = null
        }

        if(!formData?.department?.id || formData?.department?.id === '') {
            formData.department = null
        }

        if(!formData?.gender || formData?.gender === '') {
            formData.gender = null
        }

        if(formData.birthdate) {
            formData.birthdate = moment(formData.birthdate).format('YYYY-MM-DD')
        } else {
            formData.birthdate = null;
        }

        if(formData.hireDate) {
            formData.hireDate = moment(formData.hireDate).format('YYYY-MM-DD')
        } else {
            formData.hireDate = null;
        }

        if(formData.terminationDate) {
            formData.terminationDate = moment(formData.terminationDate).format('YYYY-MM-DD')
        } else {
            formData.terminationDate = null;
        }

        formData.picture = selectedFile || editData?.EmployeeById?.picture;

        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/employees/${upsertResult.data.upsert_Employee.id}`)
        }
    }

    const onFileChange = async(event) => { 
        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 720);
        } catch (err) {
            console.log(err);
        }
        setSelectedFile(file)
    };

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Colaborador</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>

                                        <div className="row justify-content-center mb-3">
                                            <div className="col-lg-4 text-center">
                                            <figure className="avatar ms-auto me-auto mb-0 mt-2 w100 aspect-ratio-1 pointer" onClick={() => inputFile.current.click()}><img src={getProfilePicture(editData?.EmployeeById)} alt="Fotografia" className="shadow-sm rounded-3 w-100 aspect-ratio-1" /></figure>
                                            { (selectedFile || editData?.EmployeeById?.picture) && <a role="button" onClick={() => null}><i className="feather-x text-dark rounded-xxl p-1 bg-grey font-xsssss"></i> Remover</a> }
                                            <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} style={{display: 'none'}} />
                                            </div>
                                        </div>
                                    
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome Completo</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("fullName", {
                                                        required: true,
                                                        onBlur: (e) => {
                                                            const name = e.target.value;
                                                            if(!watchName && name.split(' ').length > 1) {
                                                                setValue("name", name.split(' ').slice(0, 1).join(' ') + ' ' + name.split(' ').slice(-1).join(' '))
                                                            }
                                                        }
                                                    })} />
                                                    {errors.fullName && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome de Apresentação</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">ID Externo</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("externalId")} />
                                                    {errors.externalId && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Email</label>
                                                    <input type="email" className="form-control" autoComplete="off" {...register("email")} />
                                                    {errors.email && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">UPN</label>
                                                    <input type="email" className="form-control" autoComplete="off" {...register("upn")} />
                                                    {errors.upn && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telefone</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("phone")} />
                                                    {errors.phone && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telemóvel</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("mobile")} />
                                                    {errors.mobile && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telemóvel/Contacto Alternativo</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("mobilePersonal")} />
                                                    {errors.mobilePersonal && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">WhatsApp</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Formato: +351910000000"
                                                        {...register("whatsapp", {
                                                            validate: (value) => {
                                                                if (!value?.trim()) return true; // Accept empty field
                                                                const sanitizedValue = value?.replace(/\s+/g, ""); // Remove all whitespaces
                                                                return /^\+\d{1,3}\d+$/.test(sanitizedValue) || "Formato inválido. Exemplo: +351910000000";
                                                            }
                                                        })}
                                                    />
                                                    {errors.whatsapp && <span className="text-red">{errors.whatsapp.message}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Sexo</label>
                                                    <select className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("gender")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            PERSON_GENDER_OPTIONS.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.gender && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Nascimento</label>
                                                    <Controller
                                                        control={control}
                                                        name="birthdate"
                                                        rules={{ valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY') : ""}
                                                                onChange={(date) => setValue(field.name, date?.toDate())}
                                                                format={"DD/MM/YYYY"}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.birthdate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Empresa</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" {...register("company.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.CompanyList && listsData.CompanyList.map((c) => {
                                                                return (
                                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.company?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Departamento</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" {...register("department.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.DepartmentList && listsData.DepartmentList.map((c) => {
                                                                return (
                                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.department?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Função</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("jobTitle", { required: true })} />
                                                    {errors.jobTitle && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Superior Hierárquico</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("manager.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.EmployeeList && listsData.EmployeeList?.filter(e => viewerData && e.id !== viewerData?.Viewer?.employee?.id)
                                                            .map((employee) => {
                                                                return (
                                                                    <option key={employee.id} value={employee.id}>{employee.name} {employee.jobTitle && `(${employee.jobTitle})`}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.manager?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Admissão</label>
                                                    <Controller
                                                        control={control}
                                                        name="hireDate"
                                                        rules={{ valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY') : ""}
                                                                onChange={(date) => setValue(field.name, date?.toDate())}
                                                                format={"DD/MM/YYYY"}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.hireDate && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Rescisão</label>
                                                    <Controller
                                                        control={control}
                                                        name="terminationDate"
                                                        //rules={{ valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY') : null}
                                                                onChange={(date) => setValue(field.name, date?.toDate())}
                                                                format={"DD/MM/YYYY"}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.terminationDate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isExternal" {...register("isExternal")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isExternal">Colaborador Externo</label>
                                                    {errors.isExternal && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="hideFromOrgChart" {...register("hideFromOrgChart")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="hideFromOrgChart">Ocultar do Organograma</label>
                                                    {errors.hideFromOrgChart && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isActive" {...register("isActive")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isActive">Ativo</label>
                                                    {errors.isActive && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Fotografia</label>
                                                            <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar um ficheiro' }</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewEmployee;