import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';

import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import { GRAPHQL_MAX_RESULTS_LIMIT, TRAINING_ACTION_STATUS } from "../../../../config";
import Moment from "react-moment";
import { getAttachmentUrl, getProfilePicture } from "../../../../utils";
import moment from "moment";

const GET_LISTS_QUERY = gql`
    query GetLists($trainingEntityId: String!) {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } }) {
            id
            name
            company {
                id
                name
            }
            jobTitle
        }
        TrainingEntityById(id: $trainingEntityId) {
            id
            trainers {
                id
                name
            }
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        TrainingActionById(id: $id) {
            id
            trainingPlan {
                id
                name
                startDate
                endDate
            }
            company {
                id
                name
            }
            trainingEntity {
                id
                name
            }
            name
            externalId
            year
            expectedStartDate
            startDate
            endDate
            expectedTrainingHoursAmount
            trainingHoursAmount
            enableEmployeeRegistration
            maxRegistrations
            requireRegistrationApproval
            cost
            proposerDepartment {
                id
                name
            }
            targetAudience
            objectives
            comments
            evaluationExpectedDate
            evaluationAssignee {
                id
                name
            }
            evaluationDate
            evaluationEffectiveness
            evaluationResults
            evaluationComments
            status
            type {
                id
                name
            }
            area {
                id
                name
            }
            initiativeType {
                id
                name
            }
            scheduleType {
                id
                name
            }
            certificateType {
                id
                name
            }
            qualificationLevel {
                id
                name
            }
            attachments {
                id
                name
                file
            }
            trainers {
                id
                trainingEntity {
                    id
                }
                name
                email
                phone
                certificateNumber
                isActive
            }
            schedules {
                id
                location
                startDate
                endDate
                trainingHoursAmount
                attendance {
                    id
                }
            }
            registrations {
                id
                employee {
                    id
                    fullName
                    company {
                        id
                        name
                    }
                    jobTitle
                    picture
                    user {
                        id
                        name
                        picture
                    }
                }
                isApproved
                createdDate
            }
        }
    }
`;

const UPSERT_TRAINERS_MUTATION = gql`
    mutation Upsert($trainingActionId: UUID, $trainers: [inp_Trainer]) {
        upsert_TrainingAction(trainingAction:{ id: $trainingActionId, trainers: $trainers }) {
            id
        }
    }
`;

const DELETE_TRAINING_ACTION_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_TrainingAction(id: $id)
    }
`;

const UPSERT_SCHEDULE_MUTATION = gql`
    mutation Upsert($trainingActionId: UUID, $location: String, $startDate: DateTime!, $endDate: DateTime!, $trainingHoursAmount: Float!) {
        upsert_TrainingActionSchedule(trainingActionSchedule: { trainingAction: { id: $trainingActionId }, location: $location, startDate: $startDate, endDate: $endDate, trainingHoursAmount: $trainingHoursAmount }) {
            id
        }
    }
`;

const DELETE_SCHEDULE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_TrainingActionSchedule(id: $id)
    }
`;
const UPSERT_TRAINING_REGISTRATION_MUTATION = gql`
    mutation Upsert($trainingActionId: UUID, $employee: inp_Employee!) {
        upsert_TrainingActionRegistration(trainingActionRegistration: { trainingAction: { id: $trainingActionId }, employee: $employee }) {
            id
        }
    }
`;

const DELETE_TRAINING_REGISTRATION_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_TrainingActionRegistration(id: $id)
    }
`;

const UPSERT_ATTACHMENT_MUTATION = gql`
    mutation Upsert($trainingActionId: UUID, $file: Upload) {
        upsert_TrainingActionAttachment(trainingActionAttachment: { trainingAction: { id: $trainingActionId }, file: $file }) {
            id
        }
    }
`;

const DELETE_ATTACHMENT_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_TrainingActionAttachment(id: $id)
    }
`;

function AdminTrainingAction() {

    let { id } = useParams();

    const [isUploadOpen, setIsUploadOpen] = useState(false)
    const [isTrainingActionRegistrationFormOpen, setIsTrainingActionRegistrationFormOpen] = useState(false);
    const [isTrainerFormOpen, setIsTrainerFormOpen] = useState(false);
    const [isScheduleFormOpen, setIsScheduleFormOpen] = useState(false);

    const { register: registerTrainer, handleSubmit: handleSubmitTrainer, formState: { errors: errorsTrainer }, reset: resetTrainer } = useForm();
    const { register: registerSchedule, handleSubmit: handleSubmitSchedule, setValue: setValueSchedule, formState: { errors: errorsSchedule }, reset: resetSchedule, control: controlSchedule } = useForm();
    const { register: registerRegistration, handleSubmit: handleSubmitRegistration, formState: { errors: errorsRegistration }, reset: resetRegistration } = useForm();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY, {
        variables: { trainingEntityId: data?.TrainingActionById?.trainingEntity?.id },
        skip: !data?.TrainingActionById?.trainingEntity?.id
    });

    const [deleteTrainingActionMutation, { deleteTrainingActionMutationData }] = useMutation(DELETE_TRAINING_ACTION_MUTATION);
    const [upsertAttachmentMutation, { upsertAttachmentMutationData }] = useMutation(UPSERT_ATTACHMENT_MUTATION);
    const [deleteAttachmentMutation, { deletetAttachmentMutationData }] = useMutation(DELETE_ATTACHMENT_MUTATION);
    const [upsertTrainersMutation, { upsertTrainersMutationData }] = useMutation(UPSERT_TRAINERS_MUTATION);
    const [upsertScheduleMutation, { upsertScheduleMutationData }] = useMutation(UPSERT_SCHEDULE_MUTATION);
    const [deleteScheduleMutation, { deleteScheduleMutationData }] = useMutation(DELETE_SCHEDULE_MUTATION);
    const [upsertRegistrationMutation, { upsertRegistrationMutationData }] = useMutation(UPSERT_TRAINING_REGISTRATION_MUTATION);
    const [deleteTrainingRegistrationMutation, { deleteTrainingRegistrationMutationData }] = useMutation(DELETE_TRAINING_REGISTRATION_MUTATION);

    const addTrainer = async (formData) => {
        const updatedTrainers = [
            ...data.TrainingActionById.trainers,
            { id: formData.trainer.id }
        ];

        const upsertResult = await upsertTrainersMutation({ 
            variables: {
                trainingActionId: data.TrainingActionById.id,
                trainers: updatedTrainers
            },
        })

        if(upsertResult.data) {
            toast.success('Formador adicionado com sucesso!')
            setIsTrainerFormOpen(false)
            await refetch();
        }
    }

    const removeTrainer = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover o formador?')) {
            const upsertResult = await upsertTrainersMutation({ 
                variables: {
                    trainingActionId: data.TrainingActionById.id,
                    trainers: data.TrainingActionById.trainers.filter(t => t.id !== id)
                },
            })

            if(upsertResult.data) {
                toast.success('Formador removido com sucesso!')
                await refetch();
            }
        }
    }

    const deleteTrainingAction = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a ação de formação?')) {
            const deleteResult = await deleteTrainingActionMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Formação eliminada com sucesso!')
                await refetch();
            }
        }
    }

    const onFileChange = async (event) => { 
        const upsertResult = await upsertAttachmentMutation({ 
            variables: {
                trainingActionId: id,
                file: event.target.files[0]
            },
        });

        if(upsertResult.data) {
            setIsUploadOpen(false)
            toast.success('Documento anexado com sucesso!')
            await refetch();
        }
    };

    const deleteAttachment = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover o documento?')) {
            const deleteResult = await deleteAttachmentMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Documento removido com sucesso!')
                await refetch();
            }
        }
    }

    const upsertSchedule = async (formData) => {
        const upsertResult = await upsertScheduleMutation({ 
            variables: {
                trainingActionId: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Sessão adicionada!');
            setIsScheduleFormOpen(false)
            resetRegistration();
            await refetch();
        }
    }

    const deleteSchedule = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover a sessão?')) {
            const deleteResult = await deleteScheduleMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Sessão removida com sucesso!')
                await refetch();
            }
        }
    }

    const upsertRegistration = async (formData) => {
        const upsertResult = await upsertRegistrationMutation({ 
            variables: {
                trainingActionId: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Colaborador adicionado!');
            setIsTrainingActionRegistrationFormOpen(false)
            resetRegistration();
            await refetch();
        }
    }
    
    const deleteTrainingRegistration = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover o colaborador?')) {
            const deleteResult = await deleteTrainingRegistrationMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Colaborador removido com sucesso!')
                await refetch();
            }
        }
    }

    const trainers = (data && data.TrainingActionById?.trainers?.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];
    const schedules = (data && data.TrainingActionById?.schedules?.slice()
    .sort(function (a, b) {
        const d1 = new Date(a.startDate).getTime()
        const d2 = new Date(b.startDate).getTime()
        return d2 - d1;
    })) || [];
    const registrations = (data && data.TrainingActionById?.registrations?.slice().sort((a, b) => a.employee && b.employee.name && a.employee.name.localeCompare(b.employee.name))) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Ação de Formação</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.TrainingActionById?.name}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Plano de Formação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{ data?.TrainingActionById?.trainingPlan ? <Link to={`/admin/training/trainingPlans/${data?.TrainingActionById?.trainingPlan?.id}`}>{`${data?.TrainingActionById?.trainingPlan?.name} (${data?.TrainingActionById?.trainingPlan?.startDate} - ${data?.TrainingActionById?.trainingPlan?.endDate})`}</Link> : "-" }</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Empresa
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.company?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Departamento
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.proposerDepartment?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Ano
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.year}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Designação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">ID Externo
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.externalId}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Entidade Formadora
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><Link to={`/admin/training/trainingEntities/${data?.TrainingActionById?.trainingEntity.id}`}>{data?.TrainingActionById?.trainingEntity.name}</Link></span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data Prevista (Início)
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.expectedStartDate && <Moment format="DD/MM/YYYY">{data?.TrainingActionById?.expectedStartDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Início
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.startDate && <Moment format="DD/MM/YYYY HH:mm">{data?.TrainingActionById?.startDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Fim
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.endDate && <Moment format="DD/MM/YYYY HH:mm">{data?.TrainingActionById?.endDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Carga Horária Prevista
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.expectedTrainingHoursAmount} h</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Carga Horária Real
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.trainingHoursAmount} h</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Custo
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.cost} €</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Estado
                                                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">
                                                                    <span className={`d-inline-block p-1 ps-2 pe-2 ${TRAINING_ACTION_STATUS.filter(s => s.id === data?.TrainingActionById?.status)[0]?.className} font-xssss rounded-3`}><strong>{TRAINING_ACTION_STATUS.filter(s => s.id === data?.TrainingActionById?.status)[0]?.name}</strong></span>
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Destinatários
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.targetAudience}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Objetivos
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.objectives}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Observações
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.comments}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Inscrições</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Permitir Inscrições? 
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><i className={`${data?.TrainingEntityById?.enableEmployeeRegistration ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i></span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Máx. Inscrições
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.maxRegistrations}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Requer Aprovação? 
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><i className={`${data?.TrainingEntityById?.requireRegistrationApproval ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i></span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Informações Relatório Único</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Modalidade
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.type.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Área de Educação/Formação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.area.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Iniciativa
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.initiativeType.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Horário
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.scheduleType.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Tipo de Certificado/Diploma
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.certificateType.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nível de Qualificação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.qualificationLevel.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Avaliação/Resultados da Formação</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data Prevista da Avaliação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.evaluationExpectedDate && <Moment format="DD/MM/YYYY">{data?.TrainingActionById?.evaluationExpectedDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Responsável pela Avaliação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.evaluationAssignee?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data da Avaliação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.evaluationDate && <Moment format="DD/MM/YYYY">{data?.TrainingActionById?.evaluationDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Eficácia
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.evaluationEffectiveness == null ? '-' : (data?.TrainingActionById?.evaluationEffectiveness === true ? 'Sim' : 'Não')}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Resultados da Avaliação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.evaluationResults}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Comentários da Avaliação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingActionById?.evaluationComments}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Documentos</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <ul className="mb-3">
                                                        {data?.TrainingActionById?.attachments.map(attachment => (
                                                            <li key={attachment.id}>
                                                                <a href={getAttachmentUrl(attachment.file)} className="d-inline font-xssss fw-600 ls-1 text-grey-700 text-dark mb-2">
                                                                    <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                                </a>
                                                                <a role="button" onClick={() => deleteAttachment(attachment.id)}><i className="font-xss text-danger feather-trash ms-2"></i></a>
                                                            </li>
                                                        ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                                {isUploadOpen &&
                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                            <div className="form-group mb-0 w-100">
                                                                <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                                <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                                <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                                                                <span className="js-fileName">Clique para selecionar um ficheiro</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                
                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        { !isUploadOpen && <button className="p-2 ps-3 pe-3 lh-20 bg-dark border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left" onClick={() => setIsUploadOpen(true)}><i className="ti-clip text-white"></i> Anexar documento</button> }
                                                        <Link to={`/admin/training/trainingActions/edit/${data?.TrainingActionById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Formadores</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ trainers.length } resultados</h4>
                                                        <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setIsTrainerFormOpen(true)}>Adicionar Formador</a>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">Nome</th>
                                                                <th className="border-0 p-2 px-4">Email</th>
                                                                <th className="border-0 p-2 px-4">Telefone</th>
                                                                <th className="border-0 p-2 px-4">N.º Certificado</th>
                                                                <th className="border-0 p-2 px-4">Ativo?</th>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { isTrainerFormOpen &&
                                                            <tr className="bg-lightgreen">
                                                                <td className="text-center px-4">
                                                                    <select type="text" className="form-control mb-0 pt-0 pv-0 lh-24" style={{height: '28px'}} defaultValue='' {...registerTrainer("trainer.id", { required: true })}>
                                                                        <option value=''>Selecionar...</option>
                                                                        {
                                                                            listsData && listsData.TrainingEntityById && listsData.TrainingEntityById.trainers && listsData.TrainingEntityById.trainers.slice().sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
                                                                            .map((e) => {
                                                                                return (
                                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {errorsTrainer.trainer?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className="text-center">
                                                                    <a role="button" className="p-2 ps-3 pe-3 bg-success border-0 text-white text-center font-xssss fw-600 rounded-xl" onClick={handleSubmitTrainer(addTrainer)}>Adicionar</a>
                                                                    <a role="button" className="ms-2 p-2 ps-3 pe-3 bg-grey border-0 text-grey-700 text-center font-xssss fw-600 rounded-xl" onClick={() => setIsTrainerFormOpen(false)}>Cancelar</a>
                                                                </td>
                                                            </tr>
                                                            }

                                                            {trainers.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-left wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/training/trainers/edit/${value.id}`}>{value.name}</Link></span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.email}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.phone}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.certificateNumber}</span>
                                                                </td>
                                                                <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                                <td className="text-right">
                                                                    <a role="button" onClick={() => removeTrainer(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Sessões</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ schedules.length } resultados</h4>
                                                        <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setIsScheduleFormOpen(true)}>Adicionar Sessão</a>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">Início</th>
                                                                <th className="border-0 p-2 px-4">Fim</th>
                                                                <th className="border-0 p-2 px-4">Carga Horária</th>
                                                                <th className="border-0 p-2 px-4">Localização</th>
                                                                <th className="border-0 p-2 px-4">Presenças</th>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { isScheduleFormOpen &&
                                                            <tr className="bg-lightgreen">
                                                                <td className="text-center px-4">
                                                                    { /* <input type="datetime-local" className="form-control lh-24" style={{height: '28px'}} autoComplete="off" {...registerSchedule("startDate", { required: true })} /> */ }
                                                                    <Controller
                                                                        control={controlSchedule}
                                                                        name="startDate"
                                                                        rules={{ required: true, valueAsDate: true }}
                                                                        render={({
                                                                            field, onChange
                                                                        }) => (
                                                                            <DatePicker
                                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm:ss') : ""}
                                                                                onChange={(date) => setValueSchedule(field.name, date?.toDate())}
                                                                                format={"DD/MM/YYYY HH:mm"}
                                                                                plugins={[
                                                                                    <TimePicker position="right"/>,
                                                                                ]}
                                                                                className="green"
                                                                                inputClass="form-control"
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errorsSchedule.startDate && <span className="text-red">Este campo é obrigatório</span>}
                                                                </td>
                                                                <td className="text-center px-4">
                                                                    { /* <input type="datetime-local" className="form-control lh-24" style={{height: '28px'}} autoComplete="off" {...registerSchedule("endDate", { required: true })} /> */ }
                                                                    <Controller
                                                                        control={controlSchedule}
                                                                        name="endDate"
                                                                        rules={{ required: true, valueAsDate: true }}
                                                                        render={({
                                                                            field, onChange
                                                                        }) => (
                                                                            <DatePicker
                                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm:ss') : ""}
                                                                                onChange={(date) => setValueSchedule(field.name, date?.toDate())}
                                                                                format={"DD/MM/YYYY HH:mm"}
                                                                                plugins={[
                                                                                    <TimePicker position="right"/>,
                                                                                ]}
                                                                                className="green"
                                                                                inputClass="form-control"
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errorsSchedule.endDate && <span className="text-red">Este campo é obrigatório</span>}
                                                                </td>
                                                                <td className="text-center px-4">
                                                                    <input type="number" min="0" step="0.1" className="form-control lh-24" style={{height: '28px'}} autoComplete="off" {...registerSchedule("trainingHoursAmount", { required: true })} />
                                                                    {errorsSchedule.trainingHoursAmount && <span className="text-red">Este campo é obrigatório</span>}
                                                                </td>
                                                                <td className="text-center px-4">
                                                                    <input type="text" className="form-control lh-24" style={{height: '28px'}} autoComplete="off" {...registerSchedule("location")} />
                                                                    {errorsSchedule.location && <span className="text-red">Verifique este campo</span>}
                                                                </td>
                                                                <td></td>
                                                                <td className="text-center">
                                                                    <a role="button" className="p-2 ps-3 pe-3 bg-success border-0 text-white text-center font-xssss fw-600 rounded-xl" onClick={handleSubmitSchedule(upsertSchedule)}>Adicionar</a>
                                                                    <a role="button" className="ms-2 p-2 ps-3 pe-3 bg-grey border-0 text-grey-700 text-center font-xssss fw-600 rounded-xl" onClick={() => setIsScheduleFormOpen(false)}>Cancelar</a>
                                                                </td>
                                                            </tr>
                                                            }

                                                            {schedules.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.startDate}</Moment></span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.endDate}</Moment></span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.trainingHoursAmount} h</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.location}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.attendance.length}</span>
                                                                </td>
                                                                <td className="text-right">
                                                                    <Link to={`/admin/training/trainingActionSchedules/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                    <a role="button" onClick={() => deleteSchedule(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Formandos/Inscrições</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ registrations.length } resultados</h4>
                                                        <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setIsTrainingActionRegistrationFormOpen(true)}>Adicionar Colaborador</a>
                                                    </div>
                                                </div>
                                                
                                                <div className="table-content table-responsive">
                                                    <form onSubmit={handleSubmitRegistration(upsertRegistration)}>
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                                <th className="border-0 p-2 px-4">Nome</th>
                                                                <th className="border-0 p-2 px-4">Empresa</th>
                                                                <th className="border-0 p-2 px-4">Função</th>
                                                                <th className="border-0 p-2 px-4">Data de Inscrição</th>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { isTrainingActionRegistrationFormOpen &&
                                                            <tr className="bg-lightgreen">
                                                                <td className="text-center px-4"></td>
                                                                <td>
                                                                    <select type="text" className="form-control mb-0 pt-0 pv-0 lh-24" style={{height: '28px'}} defaultValue='' {...registerRegistration("employee.id", { required: true })}>
                                                                        <option value=''>Selecionar...</option>
                                                                        {
                                                                            listsData && listsData.EmployeeList && listsData.EmployeeList.slice().sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
                                                                            .map((e) => {
                                                                                return (
                                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {errorsRegistration.employee?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className="text-center">
                                                                    <a role="button" className="p-2 ps-3 pe-3 bg-success border-0 text-white text-center font-xssss fw-600 rounded-xl" onClick={handleSubmitRegistration(upsertRegistration)}>Adicionar</a>
                                                                    <a role="button" className="ms-2 p-2 ps-3 pe-3 bg-grey border-0 text-grey-700 text-center font-xssss fw-600 rounded-xl" onClick={() => setIsTrainingActionRegistrationFormOpen(false)}>Cancelar</a>
                                                                </td>
                                                            </tr>
                                                            }

                                                            {registrations.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-center ps-0">
                                                                    <img src={getProfilePicture(value.employee)} alt={value.employee.name} className="w35 rounded-circle aspect-ratio-1" />
                                                                </td>
                                                                <td className="text-left wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss">{value.employee.fullName}</span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.employee.company?.name}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.employee.jobTitle}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <a role="button" onClick={() => deleteTrainingRegistration(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminTrainingAction;