import React, { Fragment } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import Pagetitle from "../../components/Pagetitle";
import { getImageUrl, getProfilePicture } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import FeedSearchHeader from "../../components/FeedSearchHeader";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_GROUPS_QUERY = gql`
    query Get {
        Group_List(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { }, orderBy: { name: ASC }) {
            id
            name
            description
            thumbnail
            coverImage
            allowUserPosts
        }
    }
`;

function GroupList() {
    
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data, refetch } = useQuery(GET_GROUPS_QUERY);
    
    const groups = (data?.Group_List) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />
            
            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                <Pagetitle title={`${ !searchParams?.get("q") ? "Engage" : `Resultados da Pesquisa - ${searchParams?.get("q")}` }`} customButton={{link: '/admin/groups/new', icon: 'feather-plus', requiredRoles: ['admin'/*, 'post-contributor'*/]}} color="success"/>
                            
                                <CustomLoadingOverlay active={loading} />

                                { !loading && groups.length === 0 && <p className="text-center fw-600">Não existem comunidades</p> }

                                <div className="row ps-2 pe-1">
                                    { searchParams?.get("q") && <FeedSearchHeader currentPage="groups" searchQuery={searchParams?.get("q")} /> }
                                    
                                    {groups.filter(
                                        e => !searchParams.get("q")
                                                || e.name?.toLowerCase().includes(searchParams.get("q").toLowerCase().trim())
                                                || e.description?.toLowerCase().includes(searchParams.get("q").toLowerCase().trim())
                                        ).map((value , index) => (
                                    
                                    <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2" style={{cursor: 'pointer'}} onClick={() => { navigate(`/groups/${value.id}`) }}>
                                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                                            <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage: `url("${getImageUrl(value.coverImage, '/assets/images/abstract-bg-green.png')}")`}}></div>
                                            <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                                                <figure className="avatar position-absolute w75 z-index-1 left-15" style={{marginTop: `-40px` }}><img src={`${getImageUrl(value.thumbnail, '/assets/images/no-image.jpg')}`} alt={value.name} className="float-right p-1 bg-white rounded-circle w-100 aspect-ratio-1" /></figure>
                                                <div className="clearfix"></div>
                                                <h4 className="fw-700 font-xss mt-3 mb-1"><Link to={`/groups/${value.id}`}>{value.name} {value.allowUserPosts && <i className="feather-radio"></i>}</Link></h4>
                                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3 lh-3 text-max-3-lines">{value.description}</p>
                                            </div>
                                        </div>
                                    </div>

                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>
    );

}

export default GroupList;