import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import moment from "moment";

import AdminHeader from "../../../components/AdminHeader";
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import Pagetitle from "../../../components/Pagetitle";
import { USER_MOOD_SCORE, DATE_FILTERS, GRAPHQL_MAX_RESULTS_LIMIT } from "../../../config";


const GET_QUERY = gql`
    query Get($dateFilter: [inp_DateTimeFilterCondition]) {
        UserMoodList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { createdDate: DESC }, filter: { createdDate: $dateFilter }) {
            id
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    picture
                }
            }
            score
            comments
            createdDate
        }
        UserCount(filter: { isActive: { _eq: true } })
        PostCount(filter: { createdDate: $dateFilter })
        PostCommentCount(filter: { createdDate: $dateFilter })
        PostReactionCount(filter: { createdDate: $dateFilter })
    }
`;

const GET_ANALYTICS_QUERY = gql`
    query Get($startDate: OffsetDateTime) {
        PostAnalytics(startDate: $startDate) {
            id
            post {
                id
                title
                type
            }
            total
        }

        EventAnalytics(startDate: $startDate) {
            id
            event {
                id
                title
            }
            total
        }

        BenefitAnalytics(startDate: $startDate) {
            id
            benefit {
                id
                name
            }
            total
        }

        LearningItemAnalytics(startDate: $startDate) {
            id
            learningItem {
                id
                title
            }
            total
        }

        NewsArticleAnalytics(startDate: $startDate) {
            id
            newsArticle {
                id
                title
            }
            total
        }
    }
`;

function AdminInsightsTraining() {
    
    const [selectedDateFilter, setSelectedDateFilter] = useState(null)
    
    const { loading, error, data, refetch } = useQuery(GET_QUERY, {
        variables: selectedDateFilter && { 
            dateFilter: DATE_FILTERS.filter(e => e.id === selectedDateFilter)[0].value,
        }
    });

    const { loading: analyticsLoading, error: analyticsError, data: analyticsData } = useQuery(GET_ANALYTICS_QUERY, {
        variables: selectedDateFilter && { 
            startDate: DATE_FILTERS.filter(e => e.id === selectedDateFilter)[0].value?._gte
        }
    });

    useEffect(() => {
        setSelectedDateFilter('past_7_days')
    }, []);

    const results = (data && data.UserMoodList) || [];

    const happinessScore = results.reduce((total, next) => total + next.score, 0) / results.length;
    //const resultsTotalUsers = results.reduce( (acc, o) => (acc[o.user.id] = (acc[o.user.id] || 0)+1, acc), );
    const resultsTotalUsers = [...new Set(results.map((item) => item.user.id))].length;

    const engagement = data && data.UserCount && data.UserCount > 0 ? Math.round(resultsTotalUsers / data.UserCount * 100) : 0;

    console.log(resultsTotalUsers)

    var resultsByScore = {};

    [1, 2, 3, 4, 5].map(i => {
        resultsByScore[i] = [];
        results.filter(r => Math.round(r.score / 2) === i).reduce(function(res, value) {
            const date = moment(value.createdDate).format('YYYY-MM-DD')
            if (!res[date]) {
                res[date] = { date: date, total: 0 };
                resultsByScore[i].push(res[date])
            }
            res[date].total++;
            return res;
        }, {});
    })

    console.log(resultsByScore)
    
    const areaChartConfig = {
        //labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May' ,'Jun', 'Jul', 'Aug', 'Sep', 'Oct' , 'Nov', 'Dec'],
        series: [
            {
              name: USER_MOOD_SCORE.filter(s => s.score === 0)[0].name,
              data: resultsByScore[1].map(r => ({
                x: new Date(r.date).getTime(),
                y: r.total,
              }))
            },
            {
                name: USER_MOOD_SCORE.filter(s => s.score === 2)[0].name,
                data: resultsByScore[2].map(r => ({
                    x: new Date(r.date).getTime(),
                    y: r.total,
                }))
            },
            {
                name: USER_MOOD_SCORE.filter(s => s.score === 5)[0].name,
                data: resultsByScore[3].map(r => ({
                    x: new Date(r.date).getTime(),
                    y: r.total,
                }))
            },
            {
                name: USER_MOOD_SCORE.filter(s => s.score === 8)[0].name,
                data: resultsByScore[4].map(r => ({
                    x: new Date(r.date).getTime(),
                    y: r.total,
                }))
            },
            {
                name: USER_MOOD_SCORE.filter(s => s.score === 10)[0].name,
                data: resultsByScore[5].map(r => ({
                    x: new Date(r.date).getTime(),
                    y: r.total,
                }))
            },
        ],
        options: {
            chart: {
                type: 'line',
            //   width:'100%',
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true,
                    type: 'x',  
                    autoScaleYaxis: false,  
                    zoomedArea: {
                      fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                      },
                      stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                      }
                    }
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
            },
            markers: {
                size: 6,
                strokeWidth: 3,
                fillOpacity: 0,
                strokeOpacity: 0,
                hover: {
                  size: 8
                }
            },
            colors: ['#E50202', '#FE9431', '#ffcc4d', '#1e74fd', '#4cd964'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            tooltip: {
                enabled: true,
                x: {
                    show: true,
                    format: 'dd MMM yyyy',
                    formatter: undefined,
                },
                y: {
                    formatter: undefined,
                    title: {
                        formatter: (seriesName) => seriesName,
                    },
                },
            },
            xaxis: {
                type: 'datetime',
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            },
            yaxis: {
                tickAmount: 1,
                min: 0,
                //max: 5
                /*labels: {
                    formatter: function(val) {
                        return val.toFixed(0);
                    }
                }*/
            },
            
        },
    };

    const donutChartConfig = {
        
        series: [
            resultsByScore[1].reduce((total, item) => total + item.total, 0),
            resultsByScore[2].reduce((total, item) => total + item.total, 0),
            resultsByScore[3].reduce((total, item) => total + item.total, 0),
            resultsByScore[4].reduce((total, item) => total + item.total, 0),
            resultsByScore[5].reduce((total, item) => total + item.total, 0),
        ],
        options: {
            labels: [
                USER_MOOD_SCORE.filter(s => s.score === 0)[0].name,
                USER_MOOD_SCORE.filter(s => s.score === 2)[0].name,
                USER_MOOD_SCORE.filter(s => s.score === 5)[0].name,
                USER_MOOD_SCORE.filter(s => s.score === 8)[0].name,
                USER_MOOD_SCORE.filter(s => s.score === 10)[0].name
            ],
            chart: {
                type: 'pie',
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'top'
                    }
                }
            }],
            colors: ['#E50202', '#FE9431', '#ffcc4d', '#1e74fd', '#4cd964'],
            
            dataLabels: {
                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    return [name, val.toFixed(1) + '%']
                }
            },
            legend: {
                /*show: false,*/
                position: 'top',
                horizontalAlign: 'left'
            }
        },
    };

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content bg-white">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Insights > Formação"/>

                                    <div className="col-lg-12 mb-3">
                                        <div className="float-right">
                                            <Link to="/admin/moodtracker/usermoods" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Ver Lista</Link>

                                            <select className="searchCat sort ms-1 me-1" onChange={e => setSelectedDateFilter(e.target.value)} value={selectedDateFilter}>
                                                <option value="" disabled>Data</option>
                                                { DATE_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 pe-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-success feather-edit-3 font-md text-white"></i>
                                            <h4 className="text-success font-xl fw-700">{ data?.PostCount || '-' } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Publicações</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-warning feather-thumbs-up font-md text-white"></i>
                                            <h4 className="text-warning font-xl fw-700">{ data?.PostReactionCount || '-' } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Reações</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-message-circle font-md text-white"></i>
                                            <h4 className="text-secondary font-xl fw-700">{ data?.PostCommentCount || '-' } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Comentários</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-primary feather-users font-md text-white"></i>
                                            <h4 className="text-primary font-xl fw-700">{ data?.UserCount || '-' } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Utilizadores</span></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <div className="table-content table-responsive card border-0 bg-greyblue p-3">
                                            <h4 className="mont-font fw-600 font-md mb-3">Posts <span class="font-xss fw-500 text-grey-500 lh-26">mais vistos</span></h4>
                                            <table className="table order-table order-table-2 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-0">&nbsp;</th>
                                                        <th className="text-right border-0">Visualizações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { analyticsData?.PostAnalytics?.sort((a, b) => b.total - a.total).map(e => (
                                                        <tr key={e.id}>
                                                            <th className="text-grey-500 fw-500 font-xsss">{e.post.title}</th>
                                                            <td className="text-right text-grey-500 fw-500 font-xsss">{e.total}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <div className="table-content table-responsive card border-0 bg-greyblue p-3">
                                            <h4 className="mont-font fw-600 font-md mb-3">Eventos <span class="font-xss fw-500 text-grey-500 lh-26">mais vistos</span></h4>
                                            <table className="table order-table order-table-2 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-0">&nbsp;</th>
                                                        <th className="text-right border-0">Visualizações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { analyticsData?.EventAnalytics?.sort((a, b) => b.total - a.total).map(e => (
                                                        <tr key={e.id}>
                                                            <th className="text-grey-500 fw-500 font-xsss">{e.event.title}</th>
                                                            <td className="text-right text-grey-500 fw-500 font-xsss">{e.total}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <div className="table-content table-responsive card border-0 bg-greyblue p-3">
                                            <h4 className="mont-font fw-600 font-md mb-3">Benefícios <span class="font-xss fw-500 text-grey-500 lh-26">mais vistos</span></h4>
                                            <table className="table order-table order-table-2 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-0">&nbsp;</th>
                                                        <th className="text-right border-0">Visualizações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { analyticsData?.BenefitAnalytics?.sort((a, b) => b.total - a.total).map(e => (
                                                        <tr key={e.id}>
                                                            <th className="text-grey-500 fw-500 font-xsss">{e.benefit.name}</th>
                                                            <td className="text-right text-grey-500 fw-500 font-xsss">{e.total}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <div className="table-content table-responsive card border-0 bg-greyblue p-3">
                                            <h4 className="mont-font fw-600 font-md mb-3">Learning <span class="font-xss fw-500 text-grey-500 lh-26">mais vistos</span></h4>
                                            <table className="table order-table order-table-2 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-0">&nbsp;</th>
                                                        <th className="text-right border-0">Visualizações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { analyticsData?.LearningItemAnalytics?.sort((a, b) => b.total - a.total).map(e => (
                                                        <tr key={e.id}>
                                                            <th className="text-grey-500 fw-500 font-xsss">{e.learningItem.title}</th>
                                                            <td className="text-right text-grey-500 fw-500 font-xsss">{e.total}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <div className="table-content table-responsive card border-0 bg-greyblue p-3">
                                            <h4 className="mont-font fw-600 font-md mb-3">Notícias <span class="font-xss fw-500 text-grey-500 lh-26">mais vistas</span></h4>
                                            <table className="table order-table order-table-2 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-0">&nbsp;</th>
                                                        <th className="text-right border-0">Visualizações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { analyticsData?.NewsArticleAnalytics?.sort((a, b) => b.total - a.total).map(e => (
                                                        <tr key={e.id}>
                                                            <th className="text-grey-500 fw-500 font-xsss">{e.newsArticle.title}</th>
                                                            <td className="text-right text-grey-500 fw-500 font-xsss">{e.total}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <Chart
                                        options={areaChartConfig.options}
                                        series={areaChartConfig.series}
                                        type="line"
                                        height={400}
                                        width="100%"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <Chart
                                        options={donutChartConfig.options}
                                        series={donutChartConfig.series}
                                        type="pie"
                                        height={400}
                                        width={400}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminInsightsTraining;