import React, { Fragment, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import { UPLOAD_MAX_FILE_SIZE } from "../../../config";
import Moment from "react-moment";
import { getAttachmentUrl, } from "../../../utils";

import { Modal } from "react-bootstrap";

const GET_QUERY = gql`
    query Get($id: String!) {
        DocumentById(id: $id) {
            id
            name
            description
            reference
            createdDate
            category {
                id
                name
            }
            currentVersion {
                id
                changelog
                file
                fileSize
                createdDate
            }
            versions {
                id
                changelog
                file
                fileSize
                createdDate
            }
        }
    }
`;

const DELETE_DOCUMENT_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Document(id: $id)
    }
`;

const DELETE_DOCUMENT_VERSION_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_DocumentVersion(id: $id)
    }
`;

const UPSERT_DOCUMENT_VERSION_MUTATION = gql`
    mutation Upsert($document: inp_Document!, $changelog: String, $file: Upload!) {
        upsert_DocumentVersion(documentVersion: { document: $document, changelog: $changelog, file: $file }) {
            id
        }
    }
`;

const NewDocumentVersionModal = (props) => {

    const [selectedFile, setSelectedFile] = useState(null);

    const inputFile = useRef(null)
    
    const { register, handleSubmit, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [upsertDocumentVersionMutation, { upsertDocumentVersionMutationData }] = useMutation(UPSERT_DOCUMENT_VERSION_MUTATION);

    const createDocumentVersion = async (formData) => {
        if(selectedFile === null) {
            toast.error("Por favor, selecione um ficheiro")
            return
        }

        const upsertResult = await upsertDocumentVersionMutation({ 
            variables: {
                document: { id: props.document?.id },
                file: selectedFile,
                ...formData
            },
        });

        if(upsertResult.data) {
            reset();
            toast.success('Alterações guardadas!');
            props.onHide();
        } else {
            toast.error('Ocorreu um erro ao criar a versão.')
        }
    }

    const onFileChange = async(event) => {
        if(event.target.files[0].size > UPLOAD_MAX_FILE_SIZE) {
            toast.error('O ficheiro excede o tamanho permitido!')
            return;
        }

        setSelectedFile(event.target.files[0])
    };

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Nova Versão
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(createDocumentVersion)}>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                        <label className="mont-font fw-600 font-xsss">Notas / Descrição das Alterações</label>
                            <textarea className="form-control mb-0 p-3 h75 lh-16" rows="2" {...register("changelog")} ></textarea>
                            {errors.description && <span className="text-red">Verifique este campo</span>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="card mt-3 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                                <div className="form-group mb-0 w-100">
                                    <label className="mont-font fw-600 font-xsss">Ficheiro</label>
                                    <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} />
                                    
                                    <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                    <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                    <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar um ficheiro' }</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <a role="button" className="bg-grey text-center text-dark font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Cancelar</a>
            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={handleSubmit(createDocumentVersion)}>Continuar</a>
        </Modal.Footer>
      </Modal>
    );
}

function AdminDocument() {

    const navigate = useNavigate();
    
    let { id } = useParams();

    const [newVersionModalShow, setNewVersionModalShow] = useState(false);

    const { register: registerVersion, handleSubmit: handleSubmitVersion, formState: { errors: errorsVersion }, reset: resetVersion } = useForm();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT_MUTATION);
    const [deleteVersionMutation] = useMutation(DELETE_DOCUMENT_VERSION_MUTATION);

    const deleteDocument = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover o documento e todas as versões?')) {
            const deleteResult = await deleteDocumentMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Documento removido com sucesso!')
                navigate(`/admin/documents`)
            }
        }
    }

    const deleteVersion = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover a versão?')) {
            const deleteResult = await deleteVersionMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Versão removida com sucesso!')
                await refetch();
            }
        }
    }

    const versions = (data?.DocumentById?.versions?.slice().sort(function (a, b) {
            const d1 = new Date(a.createdDate).getTime()
            const d2 = new Date(b.createdDate).getTime()
            return d2 - d1;
        })) || [];


    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Documento</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.DocumentById?.name}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.DocumentById?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Categoria
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.DocumentById?.category?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Descrição
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500 post-preview-container" dangerouslySetInnerHTML={{ __html: data?.DocumentById?.description }}></span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Criação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.DocumentById?.createdDate && <Moment format="DD/MM/YYYY HH:mm">{data?.DocumentById?.createdDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        <a role="button" className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-danger me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => deleteDocument(data?.DocumentById?.id)}>Eliminar</a>
                                                        <Link to={`/admin/documents/edit/${data?.DocumentById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>
                                                
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Versões</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ versions.length } resultados</h4>
                                                        <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setNewVersionModalShow(true)}>Adicionar</a>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">Data</th>
                                                                <th className="border-0 p-2 px-4">Notas / Descrição das Alterações</th>
                                                                <th className="border-0 p-2 px-4">Download</th>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {versions.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-center wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss">{value.createdDate && <Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment>}</span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-left wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 font-xsss">{value.changelog}</span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-center">
                                                                    <a role="button" onClick={() => deleteVersion(value.id)}></a>
                                                                    <a role="button" href={getAttachmentUrl(value.file)}><i className="ti-download font-xs text-grey-500"></i></a>
                                                                </td>
                                                                <td className="text-right">
                                                                    <a role="button" onClick={() => deleteVersion(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

                <NewDocumentVersionModal
                    show={newVersionModalShow}
                    onHide={() => {
                        setNewVersionModalShow(false)
                        refetch()
                    }}
                    document={data?.DocumentById}
                />

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminDocument;