import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import moment from "moment";

import DatePicker, { DateObject } from "react-multi-date-picker";

import AdminHeader from "../../../components/AdminHeader";
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import Pagetitle from "../../../components/Pagetitle";
import { USER_MOOD_SCORE, DATE_FILTERS, GRAPHQL_MAX_RESULTS_LIMIT } from "../../../config";
import StatCard from "../../../components/StatCard";


const GET_QUERY = gql`
    query Get {
        EmployeeCount(filter: { isActive: { _eq: true } })
        UserCount(filter: { AND: [{ isActive: { _eq: true } }, { employee: { isActive: { _eq: true } } }] })

        EmployeeCountMale: EmployeeCount(filter: { AND: [{ isActive: { _eq: true } }, { gender: { _eq: MALE }  } ] })
        EmployeeCountFemale: EmployeeCount(filter: { AND: [{ isActive: { _eq: true } }, { gender: { _eq: FEMALE }  } ] })
        EmployeeCountGenderUnknown: EmployeeCount(filter: { AND: [{ isActive: { _eq: true } }, { gender: { _isNull: true }  } ] })
        CompanyList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}) {
            id
            name
        }
        DepartmentList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}) {
            id
            name
        }
    }
`;

const GET_EMPLOYEE_STATS_QUERY = gql`
    query Get {
        EmployeeAgeAvgAnalytics
        EmployeeSeniorityAvgAnalytics

        EmployeeCompanyAnalytics {
            points {
                x
                y
            }
        }
        EmployeeDepartmentAnalytics {
            points {
                x
                y
            }
        }
    }
`;

const GET_EMPLOYEE_MOVEMENT_QUERY = gql`
    query Get($dateAggregation: DateAggregation!, $startDate: OffsetDateTime!, $endDate: OffsetDateTime!) {
        EmployeeMovementAnalytics(dateAggregation: $dateAggregation, startDate: $startDate, endDate: $endDate) {
            name
            points {
                x
                y
            }
        }
        EmployeeTurnoverAnalytics(startDate: $startDate, endDate: $endDate)
    }
`;

function AdminInsightsEmployee() {

    const [dateValues, setDateValues] = useState([
        new DateObject().setMonth(1).setDay(1),
        new DateObject()
      ])
    
    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const { loading: employeeStatsLoading, error: employeeStatsError, data: employeeStatsData, refetch: employeeStatsRefetch } = useQuery(GET_EMPLOYEE_STATS_QUERY);

    const { loading: employeeMovementLoading, error: employeeMovementError, data: employeeMovementData, refetch: employeeMovementRefetch } = useQuery(GET_EMPLOYEE_MOVEMENT_QUERY, {
        variables: { 
            dateAggregation: 'MONTH',
            startDate: dateValues[0]?.format('YYYY-MM-DDT00:00:00+00:00'),
            endDate: dateValues[1]?.format('YYYY-MM-DDT23:59:59+00:00')
        },
        skip: !dateValues[0] || !dateValues[1]
    });

    const employeeMovementChartConfig = {
        series: [
            {
                name: 'Admissões',
                type: 'column',
                data: employeeMovementData?.EmployeeMovementAnalytics?.filter(s => s.name == 'hiredEmployees')[0]?.points
            },
            {
                name: 'Rescisões',
                type: 'column',
                data: employeeMovementData?.EmployeeMovementAnalytics?.filter(s => s.name == 'leavingEmployees')[0]?.points
            },
            /*{
                name: '# Colaboradores',
                type: 'line',
                data: employeeMovementData?.EmployeeMovementAnalytics.filter(s => s.name == 'activeEmployees')[0]?.points
            },*/
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false,
            },
          },
          /*title: {
            text: 'Movimentos de Colaboradores',
            align: 'center',
          },*/
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          colors: ['#00E396', '#E50202'],
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            title: {
              text: '# Colaboradores',
            },
            tickAmount: 1,
            min: 0,
            labels: {
                formatter: (val) => {
                    return Math.round(val);
                },
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
                show: true,
                formatter: function (val) {
                    return moment(val).format('MM/YYYY')
                }
            }
          },
        },  
    };

    const genderDonutChartConfig = {
        series: [
            parseInt(data?.EmployeeCountMale) || 0,
            parseInt(data?.EmployeeCountFemale) || 0,
            parseInt(data?.EmployeeCountGenderUnknown) || 0,
        ],
        options: {
            labels: [
                'Masculino',
                'Feminino',
                '(sem informação)'
            ],
            chart: {
                type: 'pie',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'top'
                    }
                }
            }],
            colors: ['#1e74fd', '#FE9431', '#ccc'],
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1, 2]
            },
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'left'
            }
        },
    };

    const companyDonutChartConfig = {
        series: employeeStatsData?.EmployeeCompanyAnalytics?.points?.map(p => parseInt(p.y)),
        options: {
            labels: employeeStatsData?.EmployeeCompanyAnalytics?.points?.map(p => data?.CompanyList?.filter(c => c.id === p.x)[0]?.name),
            chart: {
                type: 'pie',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'top'
                    }
                }
            }],
            //colors: ['#1e74fd', '#FE9431', '#ccc'],
            
            dataLabels: {
                dataLabels: {
                    //formatter: (val, { seriesIndex, w }) => w.config.series[seriesIndex]
                    formatter: function (val, opts) {
                        return val
                    },
                }
            },
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'left'
            }
        },
    };

    const departmentDonutChartConfig = {
        series: employeeStatsData?.EmployeeDepartmentAnalytics?.points?.map(p => parseInt(p.y)),
        options: {
            labels: employeeStatsData?.EmployeeDepartmentAnalytics?.points?.map(p => data?.DepartmentList?.filter(d => d.id === p.x)[0]?.name),
            chart: {
                type: 'pie',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'top'
                    }
                }
            }],
            //colors: ['#1e74fd', '#FE9431', '#ccc'],
            
            dataLabels: {
                dataLabels: {
                    //formatter: (val, { seriesIndex, w }) => w.config.series[seriesIndex]
                    formatter: function (val, opts) {
                        return val
                    },
                }
            },
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'left'
            }
        },
    };

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content bg-white">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Insights > Colaboradores"/>

                                    <div className="col-lg-12 mb-3">
                                        <div className="float-right">
                                            <Link to="/admin/employees" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Ver Lista</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-3 pe-2">
                                        <StatCard loading={false} title="Colaboradores" value={data?.EmployeeCount} valueClass="text-primary" bgColor="#e5f6ff" icon="feather-users" iconBgClass="bg-primary" />
                                    </div>
                                    <div className="col-lg-3 pe-2">
                                        <StatCard loading={false} title="Utilizadores" value={data?.UserCount} valueClass="text-success" bgColor="#e2f6e9" icon="feather-user-check" iconBgClass="bg-success" />
                                    </div>
                                    <div className="col-lg-3 pe-2">
                                        <StatCard loading={false} title="Onboarding (empathize)" value={`${data?.UserCount && data?.EmployeeCount ? Math.round(data?.UserCount / data?.EmployeeCount * 100) : 0}%`} valueClass="text-secondary" bgColor="#f6f3ff" icon="feather-user-plus" iconBgClass="bg-secondary" />
                                    </div>
                                    <div className="col-lg-3 pe-2">
                                        <StatCard loading={false} title={`Género ${data?.EmployeeCountGenderUnknown > 0 ? `(${data?.EmployeeCountGenderUnknown} sem informação)` : ''}`} value={`${data?.EmployeeCountMale} M / ${data?.EmployeeCountFemale} F`} valueClass="text-warning" bgColor="#fff0e9" icon="feather-user-plus" iconBgClass="bg-warning" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-3 pe-2">
                                        <StatCard loading={false} title="Média de Idade" value={employeeStatsData?.EmployeeAgeAvgAnalytics?.toFixed(2)} valueClass="text-lightblue2" bgColor="#f7f9fd" icon="feather-trending-up" iconBgClass="bg-greylight text-grey-500" />
                                        <StatCard loading={false} title="Antiguidade Média (anos)" value={employeeStatsData?.EmployeeSeniorityAvgAnalytics?.toFixed(2)} valueClass="text-lightblue2" bgColor="#f7f9fd" icon="feather-trending-up" iconBgClass="bg-greylight text-grey-500" />
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                            <h3 className="fw-700 mb-3 mt-0 font-sm text-grey-900">Empresa</h3>

                                            { companyDonutChartConfig?.series?.length > 0 && <Chart
                                            options={companyDonutChartConfig.options}
                                            series={companyDonutChartConfig.series}
                                            type="pie"
                                            //height={400}
                                            //width={400}
                                            />}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                            <h3 className="fw-700 mb-3 mt-0 font-sm text-grey-900">Departamento</h3>

                                            { departmentDonutChartConfig?.series?.length > 0 && <Chart
                                            options={departmentDonutChartConfig.options}
                                            series={departmentDonutChartConfig.series}
                                            type="pie"
                                            //height={400}
                                            //width={400}
                                            />}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                            <h3 className="fw-700 mb-3 mt-0 font-sm text-grey-900">Género</h3>

                                            { genderDonutChartConfig?.series?.length > 0 && <Chart
                                            options={genderDonutChartConfig.options}
                                            series={genderDonutChartConfig.series}
                                            type="pie"
                                            //height={400}
                                            //width={400}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                            <h3 className="fw-700 mb-3 mt-0 font-sm text-grey-900">Movimentação de Colaboradores</h3>

                                            <div className="float-right">
                                                <DatePicker
                                                    value={dateValues}
                                                    onChange={setDateValues}
                                                    range
                                                    portal
                                                    format={"DD/MM/YYYY"}
                                                    dateSeparator=" - " 
                                                    className="green"
                                                    inputClass="bor-0 rounded-xxxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                                />
                                            </div>
                                            
                                            <StatCard loading={false} title="Turnover" value={`${Math.round(employeeMovementData?.EmployeeTurnoverAnalytics * 100) || '-'}%`} valueClass="text-lightblue2" bgColor="#f7f9fd" icon="feather-refresh-cw" iconBgClass="bg-greylight text-grey-500" />

                                            {employeeMovementChartConfig?.series[0]?.data?.length > 0 || employeeMovementChartConfig?.series[1]?.data?.length > 0 ? (
                                            <Chart
                                                options={employeeMovementChartConfig.options}
                                                series={employeeMovementChartConfig.series}
                                                type="line"
                                                height={400}
                                                width="100%"
                                            />
                                            ) : (
                                                <span class="fw-500 mt-0 mb-2 d-block text-grey-500 font-xssss text-center">Não existem dados para o período selecionado.</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/*<div className="row">
                                    <div className="col-lg-6 mb-3">
                                        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                            <Chart
                                            options={donutChartConfig.options}
                                            series={donutChartConfig.series}
                                            type="pie"
                                            height={400}
                                            width={400}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                            <Chart
                                            options={donutChartConfig.options}
                                            series={donutChartConfig.series}
                                            type="pie"
                                            height={400}
                                            width={400}
                                            />
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminInsightsEmployee;