import React, { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import { getImageUrl } from "../../../utils";
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";

const GET_QUERY = gql`
    query Get {
        LearningItemList(orderBy: { createdDate: DESC }) {
            id
            title
            description
            thumbnail
            thumbnailExternalUrl
            category {
                id
                name
            }
            source {
                id
                name
            }
            author {
                id
                name
            }
            url
            language {
                id
                name
            }
            isVisible
            createdDate
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_LearningItem(id: $id)
    }
`;

function AdminLearningItemList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteLearningItem = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o item?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Item eliminado com sucesso!')
            }
        }
    }

    const results = (data && data.LearningItemList) || [];

    return (
        <RBACWrapper requiredRoles={['admin', 'post-contributor']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Learning"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <Link to="/admin/learning/new" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo</Link>
                                            <Link to="/admin/learning/categories" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link>
                                            <Link to="/admin/learning/sources" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Fontes</Link>
                                            <Link to="/admin/learning/authors" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Autores</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                            <th className="border-0 p-4 text-left">Título</th>
                                                            <th className="border-0 p-4">Publicado?</th>
                                                            <th className="border-0 p-4">Categoria</th>
                                                            <th className="border-0 p-4">Fonte</th>
                                                            <th className="border-0 p-4">Autor</th>
                                                            <th className="border-0 p-4">Data de Criação</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="product-thumbnail text-center ps-0 d-none d-lg-table-cell">
                                                                <img src={getImageUrl(value.thumbnail, value.thumbnailExternalUrl)} className="w75 rounded-3" />
                                                            </td>
                                                            <td className="text-left wide-column">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss">
                                                                        <Link to={`/admin/learning/edit/${value.id}`}>{value.title}</Link>
                                                                    </span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isVisible ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.category?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.source?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.author?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.createdDate ? <Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment> : '-'}</span>
                                                            </td>
                                                            
                                                            <td className="text-right">
                                                                <Link to={`/admin/learning/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteLearningItem(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminLearningItemList;