import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from "../../../../components/Appfooter";
import { getImageUrl } from "../../../../utils";
import { GRAPHQL_MAX_RESULTS_LIMIT, RECRUITMENT_JOB_OPPORTUNITY_TYPES } from "../../../../config";

const GET_LISTS_QUERY = gql`
    query GetLists {
        CompanyList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
        DepartmentList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
        JobOpportunityCategoryList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
        }
        UserList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }, filter: { isActive: { _eq: true }}) {
            id
            name
        }
        AppSettingList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { OR: [
            { key: { _eq: "recruitment.jobApplication.defaultReplyMessage" } },
            { key: { _eq: "recruitment.jobApplication.defaultRejectMessage" } }
        ] }) {
        key
        value
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $company: inp_Company!, $department: inp_Department, $type: JobOpportunityType!, $title: String!, $description: String, $referenceCode: String, $category: inp_JobOpportunityCategory!, $location: String, $recruiter: inp_User, $isActive: Boolean!, $deadline: Date, $replyMessage: String, $rejectMessage: String) {
        upsert_JobOpportunity(jobOpportunity: { id: $id, company: $company, department: $department, type: $type, title: $title, description: $description, referenceCode: $referenceCode, category: $category, location: $location, recruiter: $recruiter, isActive: $isActive, deadline: $deadline, replyMessage: $replyMessage, rejectMessage: $rejectMessage }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        JobOpportunityById(id: $id) {
            id
            company {
                id
            }
            department {
                id
            }
            type
            title
            description
            referenceCode
            category {
                id
                name
            }
            location
            recruiter {
                id
            }
            isActive
            deadline
            createdDate
        }
    }
`;

function AdminNewJobOpportunity() {

    const navigate = useNavigate();

    let { id } = useParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.JobOpportunityById);
    }, [editData, listsData]);

    const upsert = async (formData) => {
        // optional select fields
        if(!formData?.company?.id || formData?.company?.id === '') {
            formData.company = null
        }
        
        if(!formData?.department?.id || formData?.department?.id === '') {
            formData.department = null
        }

        if(!formData?.recruiter?.id || formData?.recruiter?.id === '') {
            formData.recruiter = null
        }

        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/recruitment/jobOpportunities`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Vaga</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Tipo de Vaga</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("type", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            RECRUITMENT_JOB_OPPORTUNITY_TYPES.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.type && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Referência</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("referenceCode")} />
                                                    {errors.referenceCode && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Empresa</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("company.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.CompanyList && listsData.CompanyList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.company?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Departamento</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("department.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.DepartmentList && listsData.DepartmentList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.department?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("title", { required: true })} />
                                                    {errors.title && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea className="form-control mb-0 p-3 h200 lh-16" rows="10" {...register("description")} ></textarea>
                                                    {errors.description && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Categoria</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("category.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.JobOpportunityCategoryList && listsData.JobOpportunityCategoryList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.category?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Local de Trabalho</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("location")} />
                                                    {errors.location && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Responsável RH</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("recruiter.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.UserList && listsData.UserList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.recruiter?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isActive" {...register("isActive")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isActive">Publicada?</label>
                                                    {errors.isActive && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Prazo de Candidaturas</label>
                                                    <input type="date" className="form-control" autoComplete="off" {...register("deadline")} />
                                                    {errors.deadline && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Mensagem de Resposta</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("replyMessage")}
                                                        placeholder={listsData?.AppSettingList?.filter(e => e.key === "recruitment.jobApplication.defaultReplyMessage")[0]?.value}
                                                    ></textarea>
                                                    {errors.replyMessage && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Mensagem de Rejeição</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("rejectMessage")}
                                                        placeholder={listsData?.AppSettingList?.filter(e => e.key === "recruitment.jobApplication.defaultRejectMessage")[0]?.value}
                                                    ></textarea>
                                                    {errors.rejectMessage && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewJobOpportunity;